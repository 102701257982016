<template>
	<div>

	</div>
</template>

<script>
import {ElMessage} from "element-plus";

export default {
	name: "Phone_openid",
	data(){
		return{
			user_name: '',
			user_depart: '',
			bdst: '',
			user_id: ''
		}
	},
	created() {
		const time = Date.parse(new Date())
		const url = window.location.href;
		const cs = url.split('?')[1];
		const jtcs = cs.split("&");
		const code = jtcs[0].split("=")[1];
		this.axios.post('/wxSendMessage/getOpenidSalary', (response) => {
				if (response.code === 200){
					this.axios.post('/ucUsersOpens/getUserIdByOpenid', (response1) => {
						if (response1.code === 200){
							this.$router.push({
								name: 'Phone_kaoqin',
								params: {
									key: time,
									user_id: response1.obj
								}
							});
						}
					},{
						open_id: response.obj
					})
				}
			}, {
			code: code,
		})
	},
	methods:{

	}
}
</script>

<style scoped>

</style>
